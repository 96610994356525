<template>
  <div id="root">
        <div data-reactroot="" class="app-container">
            <div class="landing-page">
                <header class="navbar cleared top clearfix active">
                    <div class="container containerHead">
                        <div class="bar-item left"><span class="icon-logo left logo">
                            <router-link to="/"><img src="../../assets/img/logo_name_white.png"></router-link> 
                    </span></div>

                    </div>
                </header>
                <div class="about child-page">
                    <div class="main-title placeholder" style="position: relative; z-index: 0; background: none;">
                        <div class="overlay"></div>
                        <div class="container">
                            <h2>关于我们</h2>
                        </div>
                        <div class="backstretch" style="left: 0px; top: 0px; overflow: hidden; margin: 0px; padding: 0px; height: 5.5rem; width: 100%; z-index: -999998; position: absolute;">
                            <div class="backstretch-item" style="position: absolute; margin: 0px; padding: 0px; border: none; width: 100%; height: 100%; z-index: -999999;">
                                <img alt="" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/assets/img/aboutUsBack.jpg" style="position: absolute; margin: 0px; padding: 0px; border: none; width: 100%; height: 5.5rem; max-width: none; left: 0px; top: 0px; right: auto; bottom: auto;">
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <h1 class="second-title">ZhaoGuoxing旗下产品简介</h1>
                        <p class="second-describFirst">我们为个人、社会组织、品牌爱好者及其他合法团体，提供基础的互联网工具以及营销平台，让其可借助互联网来进行展示与传播。我们的业务包括移动端小程序、个人展示网站和管理系统平台等创新业务。</p>
                    </div>
                    <div class="container">
                        <h1 class="second-title">我们的愿景</h1>
                        <p class="second-describSecond">我们致力于用技术为打造品牌赋能，为想拥有自己的技术品牌影响力的人和组织不断努力精进，且持续向世界输出价值和正能量。</p>
                        <p class="second-describThird">为上亿用户提供产品和服务 <span class="mtDescribe"> 我们希望我们的产品能被亿万用户所使用，并且能为用户喜爱，这将是我们最大的荣誉和价值实现。</span></p>
                        <p class="second-describ">持续向世界输出价值 <span class="mtDescribe">我们希望我们的产品能为中国和世界的组织或个人创造价值，为社会进步和经济发展做出贡献。</span></p>
                        <p class="second-describ">持续向世界输出正能量 <span class="mtDescribe">我们希望我们的产品能为中国和世界输出健康乐观、积极向上的情感和意识形态。</span></p>
                    </div>
                    <div class="image container mtWords mtAboutUsFisrtImage">
                        <img class="zi-vertical1" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/assets/img/aboutUs_center.jpg">
                    </div>
                    <!-- <div class="duty container">
                        <div class="item-list clearfix">
                            <div class="about-left">
                                <h4>应用试客</h4>
                            </div>
                            <div class="about-right">
                                <p>应用试客（<a href="http://shike.com" target="_blank">shike.com</a>）是业内知名的移动应用媒体平台，注册用户1000多万，为上千万用户提供流行应用和游戏的资讯和试玩服务，为全国1000多家主流应用和手游 (如:阿里、腾讯、百度、微博、58同城、大众点评、携程、滴滴出行和 Uber 等 )提供移动应用分发和推广服务。自产品上线以来，已累计为客户分发应用达3亿多次。</p>
                                <h5>试客使命</h5>
                                <ul>
                                    <li>让每一个应用为人所用：应用分发的本质是让用户和应用实现最优匹配，每一个应用都有适合它的用户群体，应用试客的使命就是让每一个应用为人所用。让用户更方便的找到应用、了解应用、使用应用。</li>
                                </ul>
                                <h5>试客愿景</h5>
                                <ul>
                                    <li>成为人人喜爱的应用社区：我们要以用户视角为出发点，以用户价值为导向，为用户提供有用、有趣且持续的应用社区产品。</li>
                                    <li>成为开发者喜爱的应用平台：我们要为移动开发者提供方便、高效且有持续价值的产品，我们的客户价值就是帮助客户取得更多的收入。</li>
                                    <li>做出让同事们引以为豪的产品：试客要在产品、运营、市场、技术等各方面成为让所有同事称赞，都为之骄傲的产品。全体同事们的认可是试客最大的荣誉。</li>
                                </ul>
                                <p>应用试客正在从应用媒体平台向应用社区平台演进，未来应用试客平台会为广大移动用户提供社交、应用试玩、应用客服和应用资讯等垂直产品和服务。</p>
                            </div>
                        </div>
                        <div class="duty container mtWords">
                            <div class="item-list clearfix">
                                <div class="about-left">
                                    <h4>字里行间</h4>
                                </div>
                                <div class="about-right">
                                    <p>字里行间（<a href="https://zi.com" target="_blank">zi.com</a>）是一个内容创作社区，字里行间为文字创作者提供极致体验的编辑器和电子出版服务，旨在为文字创作者提供最佳的互联网创作和出版工具，让文字承载思想并启迪智慧。</p>
                                </div>
                            </div>
                        </div>
                        <div class="duty container mtWords">
                            <div class="item-list clearfix">
                                <div class="about-left">
                                    <h4>圈子</h4>
                                </div>
                                <div class="about-right">
                                    <p>圈子（ <a href="https://quanziapp.com" target="_blank">quanziapp.com</a> ）是一个社群工具产品，为社群运营者快速搭建可独立运营的移动社区产品，提供互联网社区的基础功能和插件化的个性化功能产品，使用户可以享受移动互联网技术带来的资讯、社交、协作和各种商业服务。</p>
                                </div>
                            </div>
                        </div>
                        <div class="duty container mtWords">
                            <div class="item-list clearfix">
                                <div class="about-left">
                                    <h4>多点广告</h4>
                                </div>
                                <div class="about-right">
                                    <p>多点广告（<a href="http://admore.com" target="_blank">admore.com</a>）是基于多点旗下移动应用媒体、内容创作社区和众多移动社区联盟运营的效果广告营销平台，致力于实现广告内容与媒体内容的最优匹配，在保障用户体验的前提下为广告主实现价值和收益的最大化。</p>
                                </div>
                            </div>
                        </div>
                        <div class="image container" style="margin-bottom:0;">
                            <div class="zi-vertical2">
                                <img class="zi-horizontal1" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/assets/img/Image2.png">
                                <img class="zi-horizontal2" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/assets/img/Image6.png"></div>
                            <div class="zi-vertical2">
                                <img class="zi-horizontal1" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/assets/img/Image5.png">
                                <img class="zi-horizontal2" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/assets/img/Image3.png"></div>
                        </div>
                        <div class="company container">
                            <div class="item-list clearfix">
                                <div class="about-left">
                                    <h4>公司信息</h4>
                                </div>
                                <div class="about-right">
                                    <h5 style="margin-top: 0;">公司名称：</h5>
                                    <p>北京多点科技股份有限公司</p>
                                    <h5>公司地址：</h5>
                                    <p>北京市朝阳区朝阳路85号院5号楼C座（铜牛电影文化产业园）</p>
                                    <h5>股票代码：</h5>
                                    <p>837762.OC</p>
                                    <h5>联系电话：</h5>
                                    <p>010-5840 8079</p>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <footer class="footerCommon active">
                        <div class="container">
                            <router-link to="/"><a class="icon-logo" href="javascript:;"></a></router-link>
                            <div class="bar-item copy-right">@{{year}} ZhaoGuoxing Inc.</div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
        </div>
</template>

<script>
export default {
    // 接受父组件的值
    props: {
      'year': Number
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../assets/pc/css/style.css";
@import "../../assets/pc/css/aboutus.css";
</style>
