<template>
  <div id="root mobile">
        <div data-reactroot="" class="app-container">
            <div class="landing-page">
                <header class="navbar cleared top clearfix active">
                    <div class="container">
                        <div class="bar-item left">
                          <span class="icon-logo left logo">
                            <router-link to="/">
                                <img src="../../assets/img/logo_name_white.png">
                            </router-link>
                          </span>
                        </div>
                    </div>
                </header>
                <div class="about child-page">
                    <div class="main-title placeholder" style="position: relative; z-index: 0; background: none;">
                        <div class="overlay"></div>
                        <div class="container">
                            <h2>关于我们</h2>
                        </div>
                        <div class="backstretch" style="left: 0px; top: 0px; overflow: hidden; margin: 0px; padding: 0px; height: 178px; width: 100%; z-index: -999998; position: absolute;">
                            <div class="backstretch-item" style="position: absolute; margin: 0px; padding: 0px; border: none; width: 100%; height: 100%; z-index: -999999;">
                                <img src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/mobile/assets/img/aboutUsBack.jpg" style="position: absolute; margin: 0px; padding: 0px; border: none; width: 100%; height: 550px; max-width: none; left: 0px; top: 0px; right: auto; bottom: auto;">
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <h1 class="second-title">ZhaoGuoxing旗下产品简介</h1>
                        <p class="second-describFirst">我们为个人、社会组织、品牌爱好者及其他合法团体，提供基础的互联网工具以及营销平台，让其可借助互联网来进行展示与传播。我们的业务包括移动端小程序、个人展示网站和管理系统平台等创新业务。</p>
                    </div>
                    <div class="container">
                        <h1 class="second-title">我们的愿景</h1>
                        <p class="second-describSecond">我们致力于用技术为打造品牌赋能，为想拥有自己的技术品牌影响力的人和组织不断努力精进，且持续向世界输出价值和正能量。</p>
                        <h2>为上亿用户提供产品和服务</h2>
                        <p class="second-describThird"> 我们希望我们的产品能被亿万用户所使用，并且能为用户喜爱，这将是我们最大的荣誉和价值实现。</p>
                        <h2>持续向世界输出价值</h2>
                        <p class="second-describ">我们希望我们的产品能为中国和世界的组织或个人创造价值，为社会进步和经济发展做出贡献。</p>
                        <h2>持续向世界输出正能量</h2>
                        <p class="second-describ">我们希望我们的产品能为中国和世界输出健康乐观、积极向上的情感和意识形态。</p>
                    </div>

                    <div class="image container mtWords mtAboutUsFisrtImage">
                        <img class="zi-vertical1" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/mobile/assets/img/banner_desk.jpg">
                    </div>

                    <div class="container">
                        <!-- <div class="item-list">
                            <h4 class="infoTitle">应用试客</h4>
                            <p class="infoInfo">应用试客（<a href="http://shike.com" target="_blank">shike.com</a>）是业内知名的移动应用媒体平台，注册用户1000多万，为上千万用户提供流行应用和游戏的资讯和试玩服务，为全国1000多家主流应用和手游 (如:阿里、腾讯、百度、微博、58同城、大众点评、携程、滴滴出行和 Uber 等 )提供移动应用分发和推广服务。自产品上线以来，已累计为客户分发应用达3亿多次。</p>
                            <p class="infoInfo">应用试客正在从应用媒体平台向应用社区平台演进，未来应用试客平台会为广大移动用户提供社交、应用试玩、应用客服和应用资讯等垂直产品和服务。</p>
                        </div>
                        <div class="item-list">
                            <h4 class="infoTitle">字里行间</h4>
                            <p class="infoInfo">字里行间（<a href="http://zi.com" target="_blank">zi.com</a>）是一个内容创作社区，字里行间为文字创作者提供极致体验的编辑器和电子出版服务，旨在为文字创作者提供最佳的互联网创作和出版工具，让文字承载思想并启迪智慧。</p>

                        </div>
                        <div class="item-list">
                            <h4 class="infoTitle">圈子</h4>
                            <p class="infoInfo">圈子（ <a href="https://quanziapp.com" target="_blank">quanziapp.com</a> ）是一个社群工具产品，为社群运营者快速搭建可独立运营的移动社区产品，提供互联网社区的基础功能和插件化的个性化功能产品，使用户可以享受移动互联网技术带来的资讯、社交、协作和各种商业服务。</p>
                        </div>
                        <div class="item-list">
                            <h4 class="infoTitle">多点广告</h4>
                            <p class="infoInfo">多点广告（<a href="http://admore.com" target="_blank">Admore.com</a>）是基于多点旗下移动应用媒体、内容创作社区和众多移动社区联盟运营的效果广告营销平台，致力于实现广告内容与媒体内容的最优匹配，在保障用户体验的前提下为广告主实现价值和收益的最大化。</p>
                        </div> -->
                        <div class="item-list">
                            <div class="zi-vertical2">
                                <img class="zi-horizontal1" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/mobile/assets/img/Image2.png">
                                <img class="zi-horizontal2" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/mobile/assets/img/Image6.png"></div>
                            <div class="zi-vertical2">
                                <img class="zi-horizontal1" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/mobile/assets/img/Image5.png">
                                <img class="zi-horizontal2" src="https://admore-cas.oss-cn-hangzhou.aliyuncs.com/duodian/mobile/assets/img/Image3.png"></div>

                            <h4 class="infoTitle" style="margin-top: 50px">我们的信息</h4>
                            <div class="infoInfo">
                                <h5>名称：</h5>
                                <p>ZhaoGuoxing</p>
                                <h5>邮箱：</h5>
                                <p>i@zhaoguoxing.com</p>
                                <h5>官网：</h5>
                                <p>zhaoguoxing.com</p>
                                <h5>电话：</h5>
                                <p>155 1005 8126</p>
                            </div>
                        </div>

                    </div>

                </div>
                <footer class="footerCommon active">
                    <div class="container">
                        <router-link to="/">
                             <a class="icon-logo" href="javascript:;"></a>
                        </router-link>
                        <div class="bar-item copy-right">@{{year}} ZhaoGuoxing Inc.</div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // 接受父组件的值
    props: {
      'year': Number
    }
}
</script>

<style scoped>
@import "../../assets/mobile/css/style.css";
@import "../../assets/mobile/css/aboutus.css";
</style>
